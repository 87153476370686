import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import React, { createContext, useState, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { showError } from 'src/components/alertNotification';
import { ENTERPRISE } from 'src/constants';
import { MixpanelTracking } from 'src/services/mixpanel/mixpanel';

interface ILogin {
  email: string;
  password: string;
}

interface AuthContextType {
  user: CognitoUser | null;
  login: (value: ILogin) => Promise<void>;
  loginLoading: boolean;
  isLoggedIn: boolean;
  redirectToHome: () => void;
  setLoginLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  login: async () => {},
  loginLoading: false,
  isLoggedIn: false,
  redirectToHome: () => {},
  setLoginLoading: () => false,
  setIsLoggedIn: () => false,
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { state } = useLocation();

  const [loginLoading, setLoginLoading] = useState<boolean>(true);

  const [user, setUser] = useState<CognitoUser | null>(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { push } = useHistory();

  const redirectToHome = useCallback(() => {
    push(ENTERPRISE);
  }, [push]);

  const login = async (value: ILogin) => {
    const { email, password } = value;
    try {
      const authenticateUser = await Auth.signIn(email, password);
      const { attributes, challengeParam } = authenticateUser;
      const orgId = attributes?.['custom:orgId'] || challengeParam?.userAttributes?.['custom:orgId'];
      if (!orgId) {
        Auth.signOut();
        throw new Error('Unauthorized user');
      }
      if (authenticateUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setUser(authenticateUser);
      } else {
        MixpanelTracking.getInstance().userLogin({
          name: attributes?.['custom:fullname'],
          email: attributes?.email,
        });
        if (state) {
          push((state as { fromEmail: string }).fromEmail);
        } else redirectToHome();
      }
    } catch (e) {
      if (e instanceof Error) {
        const { message } = e;
        if (message) showError(message);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, login, loginLoading, redirectToHome, isLoggedIn, setLoginLoading, setIsLoggedIn }}
    >
      {children}
    </AuthContext.Provider>
  );
};
