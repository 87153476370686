type ValidationFunc = (length: number) => string;

export interface IValidationMessages {
  [key: string]: string | ValidationFunc;
}

const validationMessage: IValidationMessages = {
  required: 'This field is required',
  min: (length: number) => `Please enter a value greater than or equal to ${length}`,
  max: (length: number) => `Please enter a value less than or equal to ${length}`,
  minLength: (length: number) => `Please enter at least ${length} character`,
  maxLength: (length: number) => `Please enter more than ${length} character`,
  email: 'Please enter valid email.',
  url: 'Please enter valid url',
  phone: 'Please enter valid phone number.',
};
export default validationMessage;
