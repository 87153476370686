import React, { FC } from 'react';

const Loader: FC<{ className?: string }> = ({ className }: { className?: string }) => {
  return (
    <div className={'loader' + ' ' + (className ? className : '')}>
      <img src="/images/loader.svg" alt="loader" />
    </div>
  );
};
export default Loader;
